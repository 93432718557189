import React from "react";
import { Redirect } from "@reach/router";

const StoryPage: React.FC = () => {
  return (
    <>
      <Redirect noThrow to="/" />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default StoryPage;
